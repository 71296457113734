@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Rubik|Poppins);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #cccccc7a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* generals */ /* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
html, body {
  font-family: "Open Sans", sans-serif;
  color: #495466;
  font-size: 15px;
}

a, a.bold {
  color: #0073ff;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Rubik", sans-serif;
  color: #0C1B2E;
  font-size: 18px;
  line-height: 24px;
}

strong {
  color: #0C1B2E;
}

.gls-icon {
  display: inline-block;
}

/* Common sections & wrappers */
.outer-wrap {
  background-color: #d4ddea;
  min-height: 100vh;
  padding: 30px 0;
}

.inner-wrap {
  width: 95%;
  max-width: 1500px;
  margin: 0 auto;
}

.page-area--notabs {
  border-radius: 5px;
  overflow: hidden;
}

.page-area--tabbed {
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.view .page-area {
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.header-bar, .header-bar--first {
  color: #fcfdff;
  font-size: 21px;
  line-height: 25px;
  font-family: "Rubik", sans-serif;
  background-color: #0a2464;
  border-bottom: 4px solid #ed3e33;
  padding: 15px;
  box-sizing: content-box;
}
.header-bar--first {
  margin-top: -10px;
  border-radius: 0 5px 0 0;
}

.back-link-wrap {
  padding: 10px 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.back-link-wrap .gls-icon {
  margin-right: 8px;
  height: 13px;
  vertical-align: middle;
}
.back-link-wrap .gls-icon polygon {
  fill: #0073ff;
}

.view-section {
  border-top: 8px solid #edf1f5;
  padding: 15px;
}
.view-section--no-border {
  border-top: none;
}
.view-section-header .Expander::before, .view-section-header .Expander.clearfix::before {
  display: none;
}
.view-section-header .Expander .clearfix::before, .view-section-header .Expander.clearfix .clearfix::before {
  display: none;
}
.view-section.filters-section .view-section-title {
  float: left;
}
.view-section.filters-section .view-section-description {
  color: #607592;
  clear: both;
  margin-top: 0;
}

.view-action {
  padding: 10px;
  text-align: right;
}
.view-action .ant-btn {
  padding: 12px 15px;
  height: auto;
}

.output-section {
  font-size: 16px;
  line-height: 24px;
}

.single-item-header {
  display: flex;
  justify-content: initial;
  align-items: initial;
  flex-direction: initial;
  flex-wrap: initial;
  margin-bottom: 15px;
}
.single-item-icon {
  margin-right: 16px;
}
.single-item-name {
  margin-right: 16px;
}
.single-item-title {
  font-size: 21px;
  line-height: 25px;
  margin-bottom: 0;
}
.single-item-edit {
  cursor: pointer;
}
.single-item-label {
  font-size: 13px;
  color: #607592;
}
.single-item-info {
  display: inline-block;
  vertical-align: top;
  margin-left: 32px;
}
.single-item-info-main {
  display: block;
  font-size: 15px;
  font-weight: 24px;
  color: #495466;
}
.single-item-info--icon {
  text-align: center;
}
.single-item-info--icon .single-item-info-main {
  font-weight: 600;
  color: #0C1B2E;
}

/* text formatting */
.bold {
  font-weight: bold;
  color: #0C1B2E;
}
.bold * {
  font-weight: bold;
  color: #0C1B2E;
}

.italic {
  font-style: italic;
}
.italic * {
  font-weight: italic;
}

.underline {
  text-decoration: underline;
}

.underline-links a {
  text-decoration: underline;
}

.title-text {
  font-family: "Rubik", sans-serif;
  color: #0C1B2E;
  font-size: 18px;
  line-height: 24px;
}

/* alignment */
.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.inline-icon {
  display: flex;
  justify-content: initial;
  align-items: center;
  flex-direction: initial;
  flex-wrap: initial;
}
.inline-icon svg {
  margin-right: 15px;
}

.void-icon {
  display: inline-block;
  width: 24px;
}

.void-icon--nowidth {
  width: 0;
}

/* lists */
.no-list-style {
  list-style: none;
}

/* layout */
.flex-main {
  display: flex;
  justify-content: initial;
  align-items: initial;
  flex-direction: initial;
  flex-wrap: initial;
}

.flex-spread {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.clearfix::before, .clearfix::after {
  display: block;
  content: " ";
  clear: both;
}
.clearfix-after {
  display: block;
  content: " ";
  clear: both;
}
.clearfix-before {
  display: block;
  content: " ";
  clear: both;
}

/* colors */
.primary, a.primary {
  color: #0073ff;
}

.positive {
  color: #0ECC31;
}
.positive svg path {
  fill: #0ECC31;
}

a.positive {
  color: #0ECC31;
}
a.positive:hover {
  opacity: 0.75;
  color: #0ECC31;
}

.danger {
  color: #de2e28;
}

.warning {
  color: #ffa900;
}

.info-color {
  color: #607592;
}

/* override ant-btn-primary or set new class? */
.ant-btn-primary, .gls-btn-primary {
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
  text-align: center;
  background-color: #0073ff;
  border-color: #0073ff;
  border-radius: 5px;
  text-shadow: none;
  box-shadow: none;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
}
.ant-btn-primary:hover, .gls-btn-primary:hover {
  color: #ffffff;
  border-color: #4599ff;
  background-color: #4599ff;
}
.ant-btn-primary:active, .gls-btn-primary:active {
  color: #ffffff;
  border-color: #0060d4;
  background-color: #0060d4;
}

.ant-btn-default, .gls-btn-default {
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
  text-align: center;
  background-color: #607592;
  border-color: #607592;
  border-radius: 5px;
  text-shadow: none;
  box-shadow: none;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
}
.ant-btn-default:hover, .gls-btn-default:hover {
  color: #ffffff;
  border-color: #a5b7d0;
  background-color: #a5b7d0;
}
.ant-btn-default:active, .gls-btn-default:active {
  color: #ffffff;
  border-color: #495466;
  background-color: #495466;
}

input[type=text], input[type=text].ant-input,
input[type=password], input[type=password].ant-input,
input[type=email], input[type=email].ant-input,
input[type=search], input[type=search].ant-input,
input[type=date], input[type=date].ant-input,
input[type=url], input[type=url].ant-input,
input[type=tel], input[type=tel].ant-input,
input[type=number], input[type=number].ant-input, .ant-input-number,
input[type=datetime-local], input[type=datetime-local].ant-input,
input[type=time], input[type=time].ant-input,
input[type=week], input[type=week].ant-input,
textarea, textarea.ant-input,
select {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: #495466;
  display: inline-block;
  border: 1px solid #a5b7d0;
  padding: 4px 7px;
  background: #ffffff;
  border-radius: 2px;
}

.ant-input-number {
  padding: 0;
}

.ant-select-selection {
  border-radius: 2px;
  border: 1px solid #a5b7d0;
}

.ant-select-selection-item {
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
}

label {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #0C1B2E;
  display: inline-block;
  margin-right: 3px;
}

.inline-form-group {
  display: inline-block;
  margin-right: 16px;
}

/* Tweaking Ant checkboxes */
.ant-checkbox-wrapper {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
  color: #495466;
  font-weight: lighter;
}

.ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border: 1px solid #a5b7d0;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0073ff;
  border-color: #0073ff;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  -webkit-transform: rotate(45deg) scale(1.2) translate(-43%, -56%);
          transform: rotate(45deg) scale(1.2) translate(-43%, -56%);
}

.dynamic-input {
  width: 45%;
  margin-right: 8px;
}

.form-item--inline {
  display: inline-block;
  width: auto !important;
  min-width: 61px;
  margin-right: 8px;
}

@media only screen and (max-width: 600px) {
  .ant-transfer {
    flex-direction: column;
  }
}
.ant-alert-info {
  background-color: transparent;
  color: #607592;
  border: none;
}
.ant-alert-info .ant-alert-icon {
  color: #495466;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 0 0px;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.App {
  -webkit-animation: fadein 1s;
          animation: fadein 1s;
}

#Auth {
  position: absolute;
  width: 100%;
  z-index: 15;
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.LoadingScreen {
  text-align: center;
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.DataForm .ant-form-item-label {
  text-align: left;
}
.DataForm .form-item-label-description {
  display: block;
  white-space: pre-wrap;
  line-height: 1.3em;
  color: #607592;
  font-weight: lighter;
  font-size: 13px;
  margin: 0;
  height: 200px;
}
.DataForm .secondary-options {
  display: inline-block;
  vertical-align: top;
}
.DataForm .secondary-options > div {
  display: inline-block;
  vertical-align: top;
}
.LoginForm {
  max-width: 550px;
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.SiteHeader {
  height: auto;
  line-height: 1;
  padding: 0;
  background: #fff;
}
.SiteHeader .info-row, .SiteHeader .menu-row {
  border-bottom: 1px solid #e8e8e8;
  line-height: 64px;
}
.SiteHeader .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 16px;
  float: left;
}
.SiteHeader .logo .logo-text {
  line-height: 31px;
  font-weight: 1000;
  font-size: 26px;
  color: #0073ff;
}
.SiteHeader .info-menu {
  line-height: 60px;
  border-bottom: none;
  position: absolute;
  right: 0;
}
.SiteHeader .info-menu .menu-item-text {
  margin-left: 5px;
}
.SiteHeader .info-menu .anticon {
  font-size: 24px;
  margin: 0;
}
.SiteHeader .info-menu .alerts .anticon {
  margin: 0;
}
.SiteHeader .main-menu {
  float: left;
  border-bottom: none;
  width: 30%;
}
@media screen and (min-width: 768px) {
  .SiteHeader .main-menu {
    width: 80%;
  }
}
.SiteHeader .main-menu .ant-menu-item {
  padding: 0;
  border: none;
  border-bottom: none;
}
.SiteHeader .main-menu .ant-menu-item a {
  padding: 0 20px;
  border-bottom: 2px solid transparent;
}
.SiteHeader .main-menu .ant-menu-item-selected a {
  color: rgba(0, 0, 0, 0.65);
}
.SiteHeader .main-menu .ant-menu-item a.active {
  color: #1890ff;
  /* border-bottom: 2px solid #1890ff; */
  padding: 0 20px;
}
.SiteHeader .licenses {
  position: absolute;
  right: 20px;
  padding-right: 20px;
  line-height: 60px;
}
.SiteHeader .licenses p {
  margin: 0;
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.CustomerBar {
  border-radius: 5px;
  margin-bottom: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.CustomerBar .positive {
  font-size: 16px;
}
.CustomerBar.bordered {
  border: 1px solid #ccc;
}
.CustomerBar.solid {
  background: #fff;
}
.CustomerBar .ant-page-header {
  background: none;
}
.CustomerBar h2 {
  margin: 0;
  font-size: 22px;
}
.CustomerBar .logged-in-text {
  margin-right: 5px;
  font-size: 15px;
}
.CustomerBar .ant-dropdown-link {
  font-size: 15px;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .CustomerBar .logged-in-text {
    font-size: 22px;
  }
  .CustomerBar .ant-dropdown-link {
    font-size: 22px;
    font-weight: normal;
  }
}

.scroll::-webkit-scrollbar {
  display: none;
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.AlertBar {
  margin-bottom: 5px;
}
.AlertBar .ant-alert-basic {
  background-color: #fff;
  border: 1px solid #9b9b9b;
}
.AlertBar .ant-alert-with-description {
  padding: 15px 15px 15px 54px;
}
.AlertBar .ant-alert-with-description .ant-alert-icon {
  left: 14px;
}

.page-info .AlertBar .ant-alert-message {
  display: none;
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.Expander .expander-content {
  overflow-y: auto;
}
.Expander .hidden-content {
  display: none;
}
.Expander.open .hidden-content {
  display: block;
}
.Expander .toggle-button {
  float: right;
}
.Expander .toggle-button .anticon {
  color: #607592;
  font-weight: bold;
}
.Expander .toggle-button.centered {
  float: none;
  margin: 0 auto;
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.Toggler-on-label, .Toggler-switch-wrap, .Toggler-off-label {
  display: inline-block;
  margin-right: 12px;
  font-size: 16px;
}
.Toggler-off-label {
  color: #495466;
}
.Toggler-on-label {
  color: #0073ff;
}
.Toggler-switch-wrap {
  border: 1px solid #edf1f5;
  padding: 13px 14px 16px;
  border-radius: 99px;
}
.Toggler .ant-switch {
  height: 17px;
  width: 45px;
}
.Toggler .ant-switch:after {
  background-color: #f5f5f5;
  width: 26px;
  height: 26px;
  top: -5px;
  left: -5%;
}
.Toggler .ant-switch-checked {
  background-color: rgba(14, 204, 49, 0.4);
}
.Toggler .ant-switch-checked:after {
  background-color: #0ECC31;
  left: 105%;
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.TableOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: initial;
  flex-wrap: initial;
}
.TableOptions--left, .TableOptions--right {
  display: flex;
  justify-content: initial;
  align-items: center;
  flex-direction: initial;
  flex-wrap: initial;
}

.table-actions {
  display: flex;
  justify-content: initial;
  align-items: initial;
  flex-direction: initial;
  flex-wrap: initial;
}
.table-actions a, .table-actions > span {
  font-size: 15px;
  padding: 5px 15px;
  border-right: 1px solid #edf1f5;
}
.table-actions a:first-child, .table-actions > span:first-child {
  padding-left: 0;
}
.table-actions a:last-child, .table-actions > span:last-child {
  border: none;
}
.table-actions > span {
  font-weight: bold;
}

.table-info {
  font-size: 15px;
  display: flex;
  justify-content: initial;
  align-items: center;
  flex-direction: initial;
  flex-wrap: initial;
}
.table-info-name, .table-info-count {
  color: #495466;
}
.table-info-name {
  margin-right: 16px;
}
.table-info-count {
  margin-right: 24px;
}
.table-info-selector {
  margin-right: 34px;
}
.table-info-selector .ant-select-selection {
  border: none;
  color: #0C1B2E;
  font-weight: 600;
}
.table-info-arrows .inactive {
  cursor: not-allowed;
}
.table-info-arrows .gls-icon {
  margin: 0 18px;
}

.dynamic-table-table + .table-options {
  margin-top: 10px;
}

@media screen and (max-width: 1024px) {
  .table-options {
    display: block;
  }
  .table-options--left, .table-options--right {
    display: flex;
    justify-content: initial;
    align-items: center;
    flex-direction: initial;
    flex-wrap: initial;
    width: 100%;
    box-sizing: border-box;
  }
  .table-options--left {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 768px) {
  .table-options--left, .table-options--right {
    display: block;
  }
  .table-actions {
    display: block;
  }
  .table-actions a {
    width: 49%;
    min-height: 36px;
    display: inline-block;
    position: relative;
  }
  .table-actions a:nth-child(2) {
    border: none;
  }
  .table-actions a:after {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    display: block;
    content: " ";
    height: 2px;
    background: #f4f6f9;
  }
  .table-actions a.inline-icon svg {
    vertical-align: middle;
  }
  .table-actions > span {
    display: block;
    padding: 0;
    margin: 15px auto;
  }
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.DynamicTable {
  background: #fff;
  margin-bottom: 15px;
  /* ant style overrides */
  /* medium screens */
  /* small screens */
}
.DynamicTable .TableOptions a.positive {
  color: #0073ff;
}
.DynamicTable .TableOptions .positive svg path {
  fill: #0073ff;
}
.DynamicTable .panel-inner {
  padding-left: 15px;
}
.DynamicTable .panel-inner .property-label {
  font-size: 0.9em;
  color: #9b9b9b;
}
.DynamicTable .panel-inner.status-ok {
  border-left: 3px solid green;
}
.DynamicTable .panel-inner.status-warning {
  border-left: 3px solid yellow;
}
.DynamicTable .dynamic-table-table {
  display: none;
}
.DynamicTable .ant-table-thead > tr > th {
  background: none;
  color: #0C1B2E;
  font-weight: bold;
  font-size: 15px;
}
.DynamicTable .ant-table-tbody .centered-column {
  text-align: center;
}
.DynamicTable .ant-table-tbody .ant-btn {
  display: inline-block;
  margin: 0 4px;
}
.DynamicTable .ant-table-tbody .column-text {
  display: inline-block;
  max-width: 150px;
}
.DynamicTable .ant-table-pagination {
  display: none !important;
}
@media screen and (min-width: 768px) {
  .DynamicTable .dynamic-table-collapse {
    display: none;
  }
  .DynamicTable .dynamic-table-table {
    display: block;
  }
}
@media screen and (max-width: 1024px) {
  .DynamicTable .ant-table-tbody .ant-btn {
    display: block;
    margin-bottom: 5px;
    width: 100%;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 768px) {
  .DynamicTable .ant-collapse {
    margin: 25px -15px;
    border: none;
    border-radius: 0;
    background-color: #ffffff;
  }
  .DynamicTable .ant-collapse-item {
    border-bottom: 1px solid #edf1f5;
    border-top: 1px solid #edf1f5;
  }
  .DynamicTable .ant-collapse-item-active:before, .DynamicTable .ant-collapse-item-active:after {
    content: " ";
    display: block;
    height: 6px;
    background: #edf1f5;
  }
  .DynamicTable .ant-collapse-item-active:before {
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.15);
  }
  .DynamicTable .ant-collapse-item-active:after {
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.15);
  }
  .DynamicTable .ant-collapse-header {
    position: relative;
    font-size: 15px;
  }
  .DynamicTable .ant-collapse-header .panel-header-customer {
    color: #0C1B2E;
    font-weight: 600;
  }
  .DynamicTable .ant-collapse-header .panel-header-customer .gls-icon {
    fill: #495466;
  }
  .DynamicTable .ant-collapse-header:after {
    display: block;
    content: " ";
    position: absolute;
    top: -15px;
    right: -15px;
    border: 15px solid transparent;
    border-left: 15px solid #edf1f5;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  .DynamicTable .ant-collapse-header[aria-expanded=true]:after {
    border-left: 15px solid #c252f8;
  }
  .DynamicTable .ant-collapse-content {
    border-top: 1px solid #edf1f5;
  }
  .DynamicTable .ant-collapse-content-box .panel-inner.status-up {
    border-left: 2px solid #0ECC31;
  }
  .DynamicTable .ant-collapse-content-box .panel-inner.status-down {
    border-left: 2px solid #de2e28;
  }
  .DynamicTable .ant-collapse-content-box .panel-inner.status-warning {
    border-left: 2px solid #ffa900;
  }
  .DynamicTable .ant-collapse-content-box .panel-inner.status-unknown {
    border-left: 2px solid #607592;
  }
  .DynamicTable .ant-collapse-content-box .panel-inner.status-stale {
    border-left: 2px solid #9b9b9b;
  }
  .DynamicTable .ant-collapse-content-box .panel-inner .ant-btn {
    padding: 10px 25px;
    height: auto;
  }
  .DynamicTable .ant-collapse-content-box .property-label {
    color: #607592;
  }
  .DynamicTable .ant-collapse-content-box p {
    font-size: 15px;
  }
  .DynamicTable .ant-collapse-content-active {
    background-color: #fafbfc;
  }
}

/* Tables wrapped in Col.simple-table */
.simple-table {
  /* mobile simple table */
}
.simple-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 16px;
}
.simple-table-header h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  margin: 0;
}
.simple-table-panel {
  color: #495466;
}
.simple-table-panel > span {
  display: inline-block;
  margin-right: 16px;
}
.simple-table .ant-table-thead {
  display: none;
}
.simple-table .inline-icon {
  color: #495466;
}
.simple-table .inline-icon .gls-icon {
  margin-right: 16px;
}
.simple-table .ant-collapse-item {
  border-bottom: none;
}
.simple-table .ant-collapse-item:last-child {
  border-bottom: 1px solid #edf1f5;
}
.simple-table .ant-collapse-header {
  cursor: initial;
}
.simple-table .ant-collapse-header:after {
  display: none;
}
.simple-table--no-borders .ant-collapse-item {
  border: none;
}
.simple-table--no-borders .ant-collapse-item:last-child {
  border-bottom: none;
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.Dashboard {
  padding: 10px 0;
}
.Dashboard .dashboard-area {
  border-radius: 5px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.Dashboard .dashboard-area-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px;
}
.Dashboard .dashboard-area-title {
  font-size: 21px;
  margin: 0;
}
.Dashboard .dashboard-area .DynamicTable .ant-table-tbody > tr > .dashboard-actions {
  min-width: 145px;
  padding-left: 0;
  padding-right: 0;
}
.Dashboard .dashboard-area .DynamicTable .ant-table-thead > tr > th, .Dashboard .dashboard-area .DynamicTable .ant-table-tbody > tr > td {
  padding: 6px 10px;
}
@media screen and (max-width: 1024px) {
  .Dashboard .ant-row {
    display: block;
  }
  .Dashboard .DynamicTable .dynamic-table-collapse {
    display: block;
  }
  .Dashboard .DynamicTable .dynamic-table-table {
    display: none;
  }
  .Dashboard .DynamicTable .ant-collapse {
    margin: 0 !important;
    border: none;
    border-radius: 0;
    background-color: #ffffff;
  }
  .Dashboard .DynamicTable .ant-collapse-item {
    border-bottom: 1px solid #edf1f5;
    border-top: 1px solid #edf1f5;
  }
  .Dashboard .DynamicTable .ant-collapse-item-active:before, .Dashboard .DynamicTable .ant-collapse-item-active:after {
    content: " ";
    display: block;
    height: 6px;
    background: #edf1f5;
  }
  .Dashboard .DynamicTable .ant-collapse-item-active:before {
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.15);
  }
  .Dashboard .DynamicTable .ant-collapse-item-active:after {
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.15);
  }
  .Dashboard .DynamicTable .ant-collapse-header {
    position: relative;
    font-size: 15px;
  }
  .Dashboard .DynamicTable .ant-collapse-header .panel-header-customer {
    color: #0C1B2E;
    font-weight: 600;
  }
  .Dashboard .DynamicTable .ant-collapse-header .panel-header-customer .gls-icon {
    fill: #495466;
  }
  .Dashboard .DynamicTable .ant-collapse-header:after {
    display: block;
    content: " ";
    position: absolute;
    top: -15px;
    right: -15px;
    border: 15px solid transparent;
    border-left: 15px solid #edf1f5;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  .Dashboard .DynamicTable .ant-collapse-header[aria-expanded=true]:after {
    border-left: 15px solid #c252f8;
  }
  .Dashboard .DynamicTable .ant-collapse-content {
    border-top: 1px solid #edf1f5;
  }
  .Dashboard .DynamicTable .ant-collapse-content .ant-divider {
    width: 0;
  }
  .Dashboard .DynamicTable .ant-collapse-content-box .panel-inner.status-up {
    border-left: 2px solid #0ECC31;
  }
  .Dashboard .DynamicTable .ant-collapse-content-box .panel-inner.status-down {
    border-left: 2px solid #de2e28;
  }
  .Dashboard .DynamicTable .ant-collapse-content-box .panel-inner.status-warning {
    border-left: 2px solid #ffa900;
  }
  .Dashboard .DynamicTable .ant-collapse-content-box .panel-inner.status-unknown {
    border-left: 2px solid #607592;
  }
  .Dashboard .DynamicTable .ant-collapse-content-box .panel-inner.status-stale {
    border-left: 2px solid #9b9b9b;
  }
  .Dashboard .DynamicTable .ant-collapse-content-box .panel-inner > span:first-child {
    display: none;
  }
  .Dashboard .DynamicTable .ant-collapse-content-box .panel-inner .ant-btn {
    padding: 10px 25px;
    height: auto;
  }
  .Dashboard .DynamicTable .ant-collapse-content-box .property-label {
    color: #607592;
  }
  .Dashboard .DynamicTable .ant-collapse-content-box p {
    font-size: 15px;
  }
  .Dashboard .DynamicTable .ant-collapse-content-active {
    background-color: #fafbfc;
  }
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.ResourceInfo .resource-icon svg {
  font-size: 2em;
}
.ResourceInfo .resource-title {
  margin: 0;
  font-size: 21px;
  line-height: 25px;
}
.ResourceInfo .resource-title .anticon {
  opacity: 0;
}
.ResourceInfo .resource-title:hover {
  cursor: pointer;
}
.ResourceInfo .resource-title:hover .anticon {
  opacity: 1;
}
.ResourceInfo .resource-widget {
  margin: 10px 0;
}
.ResourceInfo .resource-widget p, .ResourceInfo .resource-widget strong {
  display: block;
  margin: 0;
  font-size: 15px;
}
.ResourceInfo label {
  font-size: 13px;
  font-weight: initial;
  color: #607592;
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* .gls-dygraph-* classes are ours, .dygraph-* classes are native dygraph classes. */
.DataGraph {
  background: #fff;
}
.DataGraph .dygraph-axis-label {
  color: #bbb;
  font-family: "Open Sans", sans-serif;
}
.DataGraph .dygraph-ylabel {
  font-family: "Open Sans", sans-serif;
}

/* Container elements holding many graphs */
.gls-dygraph-set-container-outer { /* graphs, control bar, and javascript */
  /* width: 1200px; */
}

.gls-dygraph-set-container-inner { /* graph objects only */
  width: auto;
  margin-left: 15px;
  margin-right: 15px;
}

/* Container element holding a single graph, including header/legend and optional range selector */
.gls-dygraph-container {
  display: inline-block;
  width: 100%;
  min-height: 250px;
}

.with-subchart .gls-dygraph-container {
  padding-bottom: 50px;
}

.narrow-graphs .gls-dygraph-container {
  width: 33.3%;
}

/* The main graph body. Dygraphs uses the initial size to determine graph/canvas size */
.gls-dygraph-graph {
  display: inline-block;
  width: 100%;
  height: 200px; /* graph object will default to 360(?) if no height set on element. */
}

.with-subchart .gls-dygraph-graph {
  height: 270px;
}

/* Header / Legend above each graph */
.gls-dygraph-legend {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-left: 56px;
  margin-right: 4px;
  background-color: #0c1b2e;
  font-size: 12px;
  color: #eee;
  overflow: hidden;
  min-height: 80px;
}

.narrow-graphs .gls-dygraph-legend {
  font-size: 85%;
}

.gls-dygraph-legend .section {
  display: block;
}

.narrow-graphs .gls-dygraph-legend span.label {
  display: none;
}

.gls-dygraph-legend span.value {
  font-weight: bold;
  white-space: pre;
}

.gls-dygraph-legend .section {
  width: calc((100% - 20px) / 3);
}

.gls-dygraph-legend .section-context span.label {
  display: none; /* I think these add clutter more than help */
}

.gls-dygraph-legend .section-context span.value {
  font-weight: normal;
  /* Ensure background color for readability
          * if a long value wraps down onto graph,
          * especially in narrow graphs.
          * Not pretty, but better than nothing. */
  display: block;
}

.gls-dygraph-legend .section-context :last-child span.value {
  font-size: 100%;
  font-weight: bold;
}

.gls-dygraph-legend .section-time {
  text-align: center;
}

.narrow-graphs .gls-dygraph-legend .section-time {
  font-size: 90%;
}

.gls-dygraph-legend .section-time span.value {
  display: block;
  position: relative;
}

.gls-dygraph-legend .section-time span.value.single-time {
  top: 33%;
}

.gls-dygraph-legend .section-values {
  text-align: right;
  padding-right: 10px;
}

.gls-dygraph-legend .section div.horiz {
  display: block;
}

/* Rotated Y-axis unit label to the left of each graph */
.dygraph-ylabel {
  font-family: sans;
  letter-spacing: 0.15em;
  font-size: 83%;
  margin-left: 16px;
}

.with-subchart .dygraph-ylabel {
  margin-left: 60px; /* Move up to center on graph display area */
}

/* Range selector */
.dygraph-rangesel-fgcanvas, .dygraph-rangesel-bgcanvas, .dygraph-rangesel-zoomhandle {
  margin-top: 10px;
}

/* Graph control bar floating at top of graph set */
.graph-control-container {
  display: flex;
  align-items: center;
  padding: 10px;
}

.graph-duration-count {
  max-width: 3.5em;
}

.graph-control {
  display: block;
  margin-right: 10px;
}

.graph-control input, .graph-control button {
  margin-right: 5px;
}

.graph-control input[type=number] {
  background-color: #fff;
}

.graph-control input[type=button] {
  color: #000;
}

.graph-control-help-toggle {
  color: #fd0;
  float: right;
  text-decoration: none;
  outline: none;
  font-size: 90%;
  position: relative;
  top: 6px;
}

.graph-control-help-hidden {
  display: none;
}

.graph-control-help {
  background-color: #046;
  color: #fff;
  margin-top: 5px;
  border-top: 2px solid #fff;
  padding-top: 8px;
  padding-left: 5px;
  margin-bottom: -15px;
}

.graph-control-help-label {
  font-weight: bold;
  font-size: 1.1em;
}

.graph-control-help-header {
  font-weight: bold;
  font-size: 1.1em;
  display: block;
  color: #fd0;
}

.graph-control-help p {
  padding-bottom: 5px;
}

/*-- Chart --*/
.c3 svg {
  font: 10px sans-serif;
  -webkit-tap-highlight-color: transparent;
}

.c3 path, .c3 line {
  fill: none;
  stroke: #000;
}

.c3 text {
  -webkit-user-select: none;
  user-select: none;
}

.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
  shape-rendering: crispEdges;
}

.c3-chart-arc path {
  stroke: #fff;
}

.c3-chart-arc rect {
  stroke: white;
  stroke-width: 1;
}

.c3-chart-arc text {
  fill: #fff;
  font-size: 13px;
}

/*-- Axis --*/
/*-- Grid --*/
.c3-grid line {
  stroke: #aaa;
}

.c3-grid text {
  fill: #aaa;
}

.c3-xgrid, .c3-ygrid {
  stroke-dasharray: 3 3;
}

/*-- Text on Chart --*/
.c3-text.c3-empty {
  fill: #808080;
  font-size: 2em;
}

/*-- Line --*/
.c3-line {
  stroke-width: 1px;
}

/*-- Point --*/
.c3-circle._expanded_ {
  stroke-width: 1px;
  stroke: white;
}

.c3-selected-circle {
  fill: white;
  stroke-width: 2px;
}

/*-- Bar --*/
.c3-bar {
  stroke-width: 0;
}

.c3-bar._expanded_ {
  fill-opacity: 1;
  fill-opacity: 0.75;
}

/*-- Focus --*/
.c3-target.c3-focused {
  opacity: 1;
}

.c3-target.c3-focused path.c3-line, .c3-target.c3-focused path.c3-step {
  stroke-width: 2px;
}

.c3-target.c3-defocused {
  opacity: 0.3 !important;
}

/*-- Region --*/
.c3-region {
  fill: steelblue;
  fill-opacity: 0.1;
}

/*-- Brush --*/
.c3-brush .extent {
  fill-opacity: 0.1;
}

/*-- Select - Drag --*/
/*-- Legend --*/
.c3-legend-item {
  font-size: 12px;
}

.c3-legend-item-hidden {
  opacity: 0.15;
}

.c3-legend-background {
  opacity: 0.75;
  fill: white;
  stroke: lightgray;
  stroke-width: 1;
}

/*-- Title --*/
.c3-title {
  font: 14px sans-serif;
}

/*-- Tooltip --*/
.c3-tooltip-container {
  z-index: 10;
}

.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
  empty-cells: show;
  box-shadow: 7px 7px 12px -9px #777777;
  opacity: 0.9;
}

.c3-tooltip tr {
  border: 1px solid #CCC;
}

.c3-tooltip th {
  background-color: #aaa;
  font-size: 14px;
  padding: 2px 5px;
  text-align: left;
  color: #FFF;
}

.c3-tooltip td {
  font-size: 13px;
  padding: 3px 6px;
  background-color: #fff;
  border-left: 1px dotted #999;
}

.c3-tooltip td > span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
}

.c3-tooltip td.value {
  text-align: right;
}

/*-- Area --*/
.c3-area {
  stroke-width: 0;
  opacity: 0.2;
}

/*-- Arc --*/
.c3-chart-arcs-title {
  dominant-baseline: middle;
  font-size: 1.3em;
}

.c3-chart-arcs .c3-chart-arcs-background {
  fill: #e0e0e0;
  stroke: #FFF;
}

.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  fill: #000;
  font-size: 16px;
}

.c3-chart-arcs .c3-chart-arcs-gauge-max {
  fill: #777;
}

.c3-chart-arcs .c3-chart-arcs-gauge-min {
  fill: #777;
}

.c3-chart-arc .c3-gauge-value {
  fill: #000;
  /*  font-size: 28px !important;*/
}

.c3-chart-arc.c3-target g path {
  opacity: 1;
}

.c3-chart-arc.c3-target.c3-focused g path {
  opacity: 1;
}
/**
 * Default styles for the dygraphs charting library.
 */

.dygraph-legend {
  position: absolute;
  font-size: 14px;
  z-index: 10;
  width: 250px;  /* labelsDivWidth */
  /*
  dygraphs determines these based on the presence of chart labels.
  It might make more sense to create a wrapper div around the chart proper.
  top: 0px;
  right: 2px;
  */
  background: white;
  line-height: normal;
  text-align: left;
  overflow: hidden;
}

/* styles for a solid line in the legend */
.dygraph-legend-line {
  display: inline-block;
  position: relative;
  bottom: .5ex;
  padding-left: 1em;
  height: 1px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  /* border-bottom-color is set based on the series color */
}

/* styles for a dashed line in the legend, e.g. when strokePattern is set */
.dygraph-legend-dash {
  display: inline-block;
  position: relative;
  bottom: .5ex;
  height: 1px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  /* border-bottom-color is set based on the series color */
  /* margin-right is set based on the stroke pattern */
  /* padding-left is set based on the stroke pattern */
}

.dygraph-roller {
  position: absolute;
  z-index: 10;
}

/* This class is shared by all annotations, including those with icons */
.dygraph-annotation {
  position: absolute;
  z-index: 10;
  overflow: hidden;
}

/* This class only applies to annotations without icons */
/* Old class name: .dygraphDefaultAnnotation */
.dygraph-default-annotation {
  border: 1px solid black;
  background-color: white;
  text-align: center;
}

.dygraph-axis-label {
  /* position: absolute; */
  /* font-size: 14px; */
  z-index: 10;
  line-height: normal;
  overflow: hidden;
  color: black;  /* replaces old axisLabelColor option */
}

.dygraph-axis-label-x {
}

.dygraph-axis-label-y {
}

.dygraph-axis-label-y2 {
}

.dygraph-title {
  font-weight: bold;
  z-index: 10;
  text-align: center;
  /* font-size: based on titleHeight option */
}

.dygraph-xlabel {
  text-align: center;
  /* font-size: based on xLabelHeight option */
}

/* For y-axis label */
.dygraph-label-rotate-left {
  text-align: center;
  /* See http://caniuse.com/#feat=transforms2d */
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

/* For y2-axis label */
.dygraph-label-rotate-right {
  text-align: center;
  /* See http://caniuse.com/#feat=transforms2d */
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.MetricsViewing .customer-links {
  display: flex;
  justify-content: initial;
  align-items: initial;
  flex-direction: initial;
  flex-wrap: initial;
}
.MetricsViewing .customer-links span, .MetricsViewing .customer-links a {
  display: inline-block;
  margin-right: 10px;
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.SelectInput {
  display: flex;
  justify-content: initial;
  align-items: initial;
  flex-direction: initial;
  flex-wrap: initial;
  margin-bottom: 15px;
}
.SelectInput-label-group, .SelectInput-input-group {
  flex: 0 0 40%;
  margin-right: 2%;
}
.SelectInput-description {
  color: #607592;
  font-size: 13px;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .SelectInput {
    display: block;
  }
  .SelectInput-label-group, .SelectInput-input-group {
    display: block;
  }
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.CheckboxInput {
  display: flex;
  justify-content: initial;
  align-items: initial;
  flex-direction: initial;
  flex-wrap: initial;
  margin-bottom: 15px;
}
.CheckboxInput-label-group, .CheckboxInput-input-group {
  flex: 0 0 40%;
  margin: 0 2% 15px 0;
}
.CheckboxInput-description {
  color: #607592;
  font-size: 13px;
  margin: 0;
}
.CheckboxInput-input-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  align-items: flex-start;
}
.CheckboxInput-input-group .ant-checkbox-wrapper, .CheckboxInput-input-group .ant-radio-wrapper {
  display: block;
  flex: 0 0 45%;
  margin: 0 2% 10px 0;
  font-size: 16px;
  font-weight: lighter;
  color: #495466;
}

@media screen and (max-width: 768px) {
  .CheckboxInput {
    display: block;
  }
  .CheckboxInput-label-group, .CheckboxInput-input-group {
    width: auto;
    display: block;
  }
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.TextInput {
  display: flex;
  justify-content: initial;
  align-items: initial;
  flex-direction: initial;
  flex-wrap: initial;
  margin-bottom: 15px;
}
.TextInput-label-group, .TextInput-input-group {
  flex: 0 0 40%;
  margin-right: 2%;
}
.TextInput-description {
  color: #607592;
  font-size: 13px;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .TextInput {
    display: block;
  }
  .TextInput-label-group, .TextInput-input-group {
    display: block;
  }
}
.SearchField {
  max-width: 500px;
  margin-bottom: 12px;
  clear: both;
}
.SearchField label {
  flex-shrink: 0;
  padding-right: 5px;
  font-weight: bold;
}
.SearchField .ant-input[type=text] {
  margin-top: 8px;
  padding: 10px;
  height: auto;
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.MultiSelector {
  margin-bottom: 12px;
  clear: both;
}
.MultiSelector .ant-transfer-list {
  min-width: 250px;
  width: auto;
  height: auto;
  border: none;
}
.MultiSelector .ant-transfer-list:first-child {
  width: 70%;
}
.MultiSelector .ant-transfer-list:first-child .ant-transfer-list-content {
  overflow: initial;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: column;
}
.MultiSelector .ant-transfer-list:nth-child(3) .ant-transfer-list-body {
  max-height: 182px;
  overflow: auto;
}
.MultiSelector .ant-transfer-list-header {
  border: none;
}
.MultiSelector .ant-transfer-list-header-selected span {
  display: none;
}
.MultiSelector .ant-transfer-list-header-selected span.ant-transfer-list-header-title {
  display: initial;
  left: 0px;
  font-size: 15px;
  line-height: 24px;
  color: #495466;
}
.MultiSelector .ant-transfer-list-body {
  border: 1px solid #d4ddea;
  border-radius: 2px;
  height: auto;
  min-height: 182px;
}
.MultiSelector .ant-transfer-list-content {
  overflow: initial;
}
.MultiSelector .ant-transfer-list-content > div {
  height: auto !important;
}
.MultiSelector .ant-transfer-list-content > div:nth-child(9) .ant-transfer-list-content-item, .MultiSelector .ant-transfer-list-content > div:nth-child(10) .ant-transfer-list-content-item, .MultiSelector .ant-transfer-list-content > div:nth-child(11) .ant-transfer-list-content-item, .MultiSelector .ant-transfer-list-content > div:nth-child(12) .ant-transfer-list-content-item {
  border-right: none;
}
.MultiSelector .ant-transfer-list-content-item {
  padding: 12px 8px;
  border-right: 1px solid #d4ddea;
  position: relative;
  overflow: initial;
}
.MultiSelector .ant-transfer-list-content-item:after {
  display: block;
  width: 94%;
  margin: 0 3%;
  height: 1px;
  background: #d4ddea;
  content: " ";
  position: absolute;
  bottom: -1px;
  left: 0;
}
.MultiSelector .ant-transfer-list-content-item > span {
  display: block;
  padding: 0;
}
.MultiSelector .ant-transfer-list-content-item > span:empty {
  display: none;
}
.MultiSelector .ant-transfer-list-content-item .anticon {
  opacity: 0;
  float: right;
  margin-top: 2px;
  font-size: 19px;
}
.MultiSelector .ant-transfer-list-content-item:hover {
  background: #f5f7fa;
}
.MultiSelector .ant-transfer-list-content-item:hover .anticon {
  opacity: 1;
}
.MultiSelector .ant-transfer-list-content .ant-transfer-list-content-item-disabled {
  display: none;
}
.MultiSelector .ant-transfer .ant-checkbox-wrapper {
  display: none;
}
.MultiSelector .pagination-wrapper {
  width: 65%;
  overflow: hidden;
  margin-top: 5px;
}
.MultiSelector .pagination-wrapper .ant-pagination {
  float: right;
}
.MultiSelector .pagination-wrapper .ant-pagination li.ant-pagination-item:nth-last-child(2) {
  margin-right: 0;
}
.MultiSelector .pagination-wrapper .ant-pagination-next, .MultiSelector .pagination-wrapper .ant-pagination-prev {
  display: none;
}
.MultiSelector .loading {
  height: 158px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .MultiSelector .ant-transfer-list:first-child {
    width: 100%;
  }
  .MultiSelector .ant-transfer-list:first-child .ant-transfer-list-content {
    overflow: initial;
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
  }
  .MultiSelector .ant-transfer-list:nth-child(3) {
    width: 100%;
  }
  .MultiSelector .ant-transfer-list:nth-child(3) .ant-transfer-list-body {
    min-height: 150px;
    max-height: 150px;
  }
  .MultiSelector .ant-transfer-list-content {
    overflow: initial;
  }
  .MultiSelector .ant-transfer-list-content > div:nth-child(n+9) {
    display: none;
  }
  .MultiSelector .ant-transfer-operation {
    display: none;
  }
}
@media screen and (min-width: 769px) {
  .MultiSelector .ant-transfer {
    display: flex;
  }
  .MultiSelector .ant-transfer-list:first-child {
    width: 65%;
  }
  .MultiSelector .ant-transfer-list:nth-child(3) {
    flex: 1 1;
    max-width: 275px;
  }
  .MultiSelector .ant-transfer-operation {
    display: flex;
    justify-content: center;
    align-items: initial;
    flex-direction: column;
    flex-wrap: initial;
  }
}
.Filter .single-filter {
  float: left;
  margin-right: 16px;
  margin-bottom: 12px;
}
.Filter .single-filter label {
  font-weight: bold;
  padding-right: 5px;
}
.Filter .ant-select-dropdown-menu-item {
  text-overflow: initial;
  overflow: visible;
}
.Filter .filter-select {
  min-width: 120px;
  width: auto;
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.TabbedPage .ant-tabs {
  overflow: initial;
}
.TabbedPage > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}
.TabbedPage > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  border-radius: 0 5px 5px 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.TabbedPage > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
  border: none;
}
.TabbedPage > .ant-tabs-card .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: #fcfdff;
  border-top: 3px solid #fcfdff;
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  color: #a5b7d0;
  margin-right: 5px;
}
.TabbedPage > .ant-tabs-card .ant-tabs-bar .ant-tabs-tab-active {
  border: none;
  background: #fff;
  border-top: 3px solid #c252f8;
  color: #0C1B2E;
}

/* nested tabs */
.view .TabbedPage .card-container > .ant-tabs-card > .ant-tabs-bar {
  border-top: 8px solid #edf1f5;
  background: #f4f6f9;
  padding-top: 10px;
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.Help.view .page-area {
  margin-top: 10px;
}
.Help.view .ant-collapse-item, .Help.view .ant-accordion-item {
  border-bottom: none;
}
.Help.view .ant-collapse-header, .Help.view .ant-accordion-header {
  color: #fcfdff;
  font-size: 21px;
  line-height: 25px;
  font-family: "Rubik", sans-serif;
  background-color: #0a2464;
  border-bottom: 4px solid #ed3e33;
  padding: 15px;
  box-sizing: content-box;
}
.Help.view .ant-collapse-content, .Help.view .ant-accordion-content {
  padding: 20px;
}
.Help.view .ant-collapse-content-box, .Help.view .ant-accordion-content-box {
  padding: 0;
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.KeyValue {
  margin-bottom: 30px;
}
.KeyValue .key-value-header {
  padding-top: 10px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.KeyValue .key-value-header .ant-col {
  padding-left: 12px !important;
  padding-right: 12px !important;
  box-sizing: border-box;
  font-weight: bold;
}
.KeyValue .key-value-row {
  margin-top: 10px;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.KeyValue .key-value-input .ant-input-wrapper .gls-icon {
  width: 22px;
  height: 22px;
}
.KeyValue .key-value-input .ant-input, .KeyValue .key-value-input-textarea {
  margin: 0 3px;
  border: none;
  width: 98%;
  border-bottom: 1px solid #edf1f5;
}
.KeyValue .ant-input-group-addon {
  border: none;
  background: none;
}
.KeyValue .add-row {
  float: right;
  padding: 11px;
}
.KeyValue .blank-icon {
  padding: 0 7px;
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
@media screen and (max-width: 768px) {
  .HostsAdd .SelectInput {
    display: block;
  }
  .HostsAdd .SelectInput-label-group {
    display: block;
  }
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.HostsEditQueriesAdd .TextInput, .HostsEditQueriesAdd .SelectInput, .HostsEditQueriesAdd .CheckboxInput {
  margin-bottom: 30px;
}
.HostsEditQueriesAdd .TextInput-label-group, .HostsEditQueriesAdd .SelectInput-label-group, .HostsEditQueriesAdd .CheckboxInput-label-group {
  flex: 0 0 25%;
  margin-right: 5%;
}
@media screen and (max-width: 768px) {
  .HostsEditQueriesAdd .TextInput, .HostsEditQueriesAdd .SelectInput, .HostsEditQueriesAdd .CheckboxInput {
    display: block;
    margin: 0 0 20px;
  }
  .HostsEditQueriesAdd .keep-flex {
    display: flex;
  }
  .HostsEditQueriesAdd .keep-flex .TextInput-label-group, .HostsEditQueriesAdd .keep-flex .TextInput-input-group {
    flex: 0 0 40%;
    margin-right: 5%;
  }
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.Hosts.view .hosts-section-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: initial;
  flex-wrap: initial;
}
.Hosts.view .hosts-section-options--left, .Hosts.view .hosts-section-options--right {
  display: flex;
  justify-content: initial;
  align-items: center;
  flex-direction: initial;
  flex-wrap: initial;
}
@media screen and (max-width: 768px) {
  .Hosts.view .ant-collapse-header .panel-header-hosts .ant-checkbox-wrapper, .Hosts.view .ant-collapse-header .panel-header-hosts .gls-icon {
    margin-right: 16px;
  }
  .Hosts.view .ant-collapse-content {
    border-top: none;
    background: #ffffff;
  }
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.Notifications .TabbedPage .card-container > .ant-tabs-card > .ant-tabs-bar {
  border-top: none;
  background: transparent;
}
.Notifications .notifications-links-wrap {
  z-index: 999;
  margin-bottom: -40px;
}
@media (max-width: 885px) {
  .Notifications .notifications-links-wrap {
    margin-left: 0px !important;
    margin-bottom: 0px !important;
    max-width: 100% !important;
    flex: 0 0 100%;
  }
}
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Colors
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* theme colors */
/* font colors */
/* border color */
/* ANT menu colors */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Fonts
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* font families */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layout
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Breakpoints (I couldn't find this anywhere else... added 12/16/2021)
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.NotificationsLinks {
  display: flex;
  border-radius: 5px;
  padding-bottom: 0px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.NotificationsLinks .notifications-link {
  display: block;
  position: relative;
}
.NotificationsLinks a {
  display: block;
  flex: 2 1;
  padding: 10px 15px;
  border-top: 1px solid #edf1f5;
  position: relative;
  font-size: 18px;
  text-align: center;
  background: #ffffff;
}
.NotificationsLinks a::after {
  display: block;
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: #edf1f5;
}
.NotificationsLinks a.active::after {
  display: block;
  position: absolute;
  right: 0;
  top: -1px;
  height: 3px;
  width: 100%;
  background-color: #c252f8;
  content: " ";
}
.NotificationsLinks a .anticon {
  margin-right: 5px;
}
.TimePeriodsAdd .time-period--days div > span:first-child {
  display: inline-block;
  width: 40px;
}
.TimePeriodsAdd strong {
  font-weight: 600;
}
