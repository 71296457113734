/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Layouts, alignments, positioning
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
@mixin flex-main($justify: initial, $align: initial, $direction: initial, $wrap: initial) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
  flex-wrap: $wrap;
}

@mixin flex-spread {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@mixin inner-wrap {
  width: 95%;
  max-width: 1500px;
  margin: 0 auto;
}

@mixin clearfix {
  display: block;
  content: " ";
  clear: both;
}


/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Theme design
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
@mixin page-area {
  background: #ffffff;
  border-radius: $main-radius;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}


/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  Prefixes
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
@mixin transitions($properties: all, $duration: 0.25s, $timing: ease-in-out) {
  transition: $properties $duration $timing;
  -webkit-transition: $properties $duration $timing;
  -moz-transition: $properties $duration $timing;
  -ms-transition: $properties $duration $timing;
  -o-transition: $properties $duration $timing;
}