@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Rubik|Poppins');

html, body {
  font-family: $body-font;
  color: $main-font-color;
  font-size: 15px;
}

a, a.bold {
  color: $primary-color;
  @include transitions;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $titles-font;
  color: $dark-font-color;
  font-size: 18px;
  line-height: 24px;
}

strong {
  color: $dark-font-color;
}

.gls-icon {
  display: inline-block;
}

/* Common sections & wrappers */
.outer-wrap {
  background-color: $main-background;
  min-height: 100vh;
  padding: 30px 0;
}

.inner-wrap {
  @include inner-wrap;
}

.page-area {
  
  &--notabs {
    border-radius: $main-radius;
    overflow: hidden;
  }
}

.page-area--tabbed {
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.view .page-area {
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.header-bar {
  color: $header-bar-font-color;
  font-size: 21px;
  line-height: 25px;
  font-family: $titles-font;
  background-color: $primary-color-dark;
  border-bottom: $header-border;
  padding: $view-section-padding;
  box-sizing: content-box;

  &--first {
    @extend .header-bar;
    margin-top: -$page-area-padding;
    border-radius: 0 $main-radius 0 0;
  }
}

.back-link-wrap {
  padding: 10px 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  .gls-icon {
    margin-right: 8px;
    height: 13px;
    vertical-align: middle;

    polygon {
      fill: $primary-color;
    }
  }
}

.view-section {
  border-top: $section-border;
  padding: $view-section-padding;

  &--no-border {
    border-top: none;
  }

  &-header {
    // font-weight: 600;
    // color: $dark-font-color;

    .Expander, .Expander.clearfix {
      &::before {
        display: none;
      }
      .clearfix {
        &::before {
          display: none;
        }
      }
    }
  }

  &.filters-section {
    .view-section-title {
      // font-family: $body-font;
      // font-weight: 600;
      // font-size: 15px;
      // color: $dark-font-color;
      float: left;
      // margin-bottom: 0;
    }
  
    .view-section-description {
      color: $info-font-color;
      clear: both;
      margin-top: 0
    }
  }

}

.view-action {
  // margin-bottom: 15px;
  padding: $page-area-padding;
  text-align: right;

  .ant-btn {
    padding: 12px 15px;
    height: auto;
  }
}

.output-section {
  font-size: 16px;
  line-height: 24px;
}

.single-item {
  &-header {
    @include flex-main;
    margin-bottom: 15px;
  }

  &-icon {
    margin-right: 16px;
  }

  &-name {
    margin-right: 16px;
  }

  &-title {
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 0;
  }

  &-edit {
    cursor: pointer;
  }

  &-label {
    font-size: 13px;
    color: $info-font-color;
  }

  &-info {
    display: inline-block;
    vertical-align: top;
    margin-left: 32px;

    &-main {
      display: block;
      font-size: 15px;
      font-weight: 24px;
      color: $secondary-active-color;
    }

    &--icon {
      text-align: center;

      .single-item-info-main {
        font-weight: 600;
        color: $dark-font-color;
      }
    }
  }
}

