@mixin gls-button($background: $primary-color, $hover: $primary-hover-color, $active: $primary-active-color) {
  color: #ffffff;
  font-family: $body-font;
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
  text-align: center;
  background-color: $background;
  border-color: $background;
  border-radius: $main-radius;
  text-shadow: none;
  box-shadow: none;
  @include transitions;

  &:hover {
    color: #ffffff;
    border-color: $hover;
    background-color: $hover;
  }

  &:active {
    color: #ffffff;
    border-color: $active;
    background-color: $active;
  }
}

/* override ant-btn-primary or set new class? */
.ant-btn-primary, .gls-btn-primary {
  @include gls-button;
}

.ant-btn-default, .gls-btn-default {
  @include gls-button($secondary-color, $secondary-hover-color, $secondary-active-color);
}