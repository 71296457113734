.SearchField {
    // display: flex;
    max-width: 500px;
    // flex-direction: row;
    // align-items: center;
    margin-bottom: 12px;
    clear: both;

    label {
        flex-shrink: 0;
        padding-right: 5px;
        font-weight: bold;
    }

    .ant-input[type="text"] {
        margin-top: 8px;
        padding: 10px;
        height: auto
    }
}