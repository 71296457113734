.Filter {
    .single-filter {
        float: left;
        margin-right: 16px;
        margin-bottom: 12px;

        label {
            font-weight: bold;
            padding-right: 5px;
        }

    }
    
    .ant-select-dropdown-menu-item {
        text-overflow: initial;
        overflow: visible;
    }

    .filter-select {
        min-width: 120px;
        width: auto;
    }
}
