.Notifications {
 
  .TabbedPage .card-container > .ant-tabs-card > .ant-tabs-bar {
   border-top: none;
   background: transparent;
 } 
 
  .notifications-links-wrap {
    z-index: 999;
    margin-bottom: -40px;
  }

  @media (max-width: 885px){
      .notifications-links-wrap{
          margin-left: 0px !important;
          margin-bottom: 0px !important;
          max-width: 100% !important;
          flex: 0 0 100%;
      }
  }
}