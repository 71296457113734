@import "../theming/general/vars";
@import "../theming/general/mixins";

.NotificationsLinks {
  display: flex;
  border-radius: $main-radius;
  padding-bottom: 0px;
  margin-bottom: 10px;
  // background: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);

  .notifications-link {
    display: block;
    position: relative;    
  }

  a {
    display: block;
    flex: 2;
    padding: 10px 15px;
    border-top: $main-border;
    // border-radius: $main-radius;
    position: relative;
    font-size: 18px;
    text-align: center;
    background: #ffffff;

    &::after {
      display: block;
      position: absolute;
      // right: 15px;
      // top: 50%;
      // transform: translate(0, -50%);
      height: 10px;
      width: 10px;
      background-color: $main-border-color;
      // border-radius: 50%;
      // content: " ";
    }

    &.active {
      // padding-bottom: 20px;
      // border-radius: 0 $main-radius 0 0;
      // border-top: 3px solid $selected-tab;

      &::after {
        
        display: block;
        position: absolute;
        right: 0;
        top: -1px;
        // transform: translate(0, -50%);
        height: 3px;
        width: 100%;
        background-color: $selected-tab;
        // border-radius: 50%;
        content: " ";
      }
    }
    .anticon {
      margin-right: $main-gutter;
    }
  }
}

.tabbed-view .NotificationsLinks {
  // margin-bottom: -30px;
}