@import '~antd/dist/antd.css';
@import './theming/theme.scss';

.ant-tabs-top>.ant-tabs-nav,
.ant-tabs-bottom>.ant-tabs-nav,
.ant-tabs-top>div>.ant-tabs-nav,
.ant-tabs-bottom>div>.ant-tabs-nav {
    margin: 0 0 0 0px;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.App {
    animation: fadein 1s;
}

#Auth {
    position: absolute;
    width: 100%;
    z-index: 15;
}