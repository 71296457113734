input[type="text"], input[type="text"].ant-input,
input[type="password"], input[type="password"].ant-input,
input[type="email"], input[type="email"].ant-input,
input[type="search"], input[type="search"].ant-input,
input[type="date"], input[type="date"].ant-input,
input[type="url"], input[type="url"].ant-input,
input[type="tel"], input[type="tel"].ant-input,
input[type="number"], input[type="number"].ant-input, .ant-input-number,
input[type="datetime-local"], input[type="datetime-local"].ant-input,
input[type="time"], input[type="time"].ant-input,
input[type="week"], input[type="week"].ant-input,
textarea, textarea.ant-input,
select {
  font-family: $body-font;
  font-size: 15px;
  line-height: 24px;
  color: $main-font-color;
  display: inline-block;
  border: 1px solid #a5b7d0;
  padding: 4px 7px;
  background: #ffffff;
  border-radius: 2px;
}

.ant-input-number {
  padding: 0;
}

.ant-select-selection {
  border-radius: 2px;
  border: 1px solid #a5b7d0;
}
.ant-select-selection-item {
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
}
label {
  font-family: $body-font;
  font-size: 15px;
  font-weight: 600;
  color: $dark-font-color;
  display: inline-block;
  margin-right: 3px;
}

.inline-form-group {
  display: inline-block;
  margin-right: 16px;
}


/* Tweaking Ant checkboxes */
.ant-checkbox-wrapper {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
  color: $main-font-color;
  font-weight: lighter;
}

.ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border: 1px solid $secondary-hover-color;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-color;
  border-color: $primary-color;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1.2) translate(-43%, -56%);
}


.dynamic-input {
  width: 45%;
  margin-right: 8px;
}

.dynamic-input--left {
  // width: 50%;
}

.dynamic-input--right {
  // width: 49%;
  // margin-left: 1%;
}

.form-item--inline {
  display: inline-block;
  width: auto !important;
  min-width: 61px;
  margin-right: 8px;
}

// Multiselector input responsiveness
@media only screen and (max-width: 600px){
  .ant-transfer {
    flex-direction: column;
  }
}