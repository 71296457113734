/* text formatting */
.bold {
  font-weight: bold;
  color: $dark-font-color;
  
  * {
    font-weight: bold;
    color: $dark-font-color;
  }
}

.italic {
  font-style: italic;

  * {
    font-weight: italic;
  }
}

.underline {
  text-decoration: underline;
}

.underline-links {
  a {
    text-decoration: underline;
  }
}

.title-text {
  font-family: $titles-font;
  color: $dark-font-color;
  font-size: 18px;
  line-height: 24px;
}


/* alignment */
.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.inline-icon {
  @include flex-main(initial, center);
  svg {
    margin-right: 15px;
  }
}

.void-icon {
  display: inline-block;
  width: 24px;
}

.void-icon--nowidth {
  width: 0;
}

/* lists */
.no-list-style {
  list-style: none;
}

/* layout */
.flex-main {
  @include flex-main;
}

.flex-spread {
  @include flex-spread;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.clearfix {
  &::before, &::after {
    @include clearfix;
  }

  &-after {
    @include clearfix;
  }

  &-before {
    @include clearfix;
  }
}

/* colors */
.primary, a.primary {
  color: $primary-color;
}

.positive {
  color: $positive-color;

  svg path {
    fill: $positive-color;
  }
}

a.positive {
  color: $positive-color;

  &:hover {
    opacity: 0.75;
    color: $positive-color; // lighten($positive-color, 5%);
  }
}

.danger {
  color: $alert-color;
}

.warning {
  color: $warning-color;
}

.info-color {
  color: $info-font-color;
}